import React from "react";

import "./Footer.css";
import { Link } from "react-router-dom";

import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

function Footer() {
  const useLink = [
    {
      link: "/aboutus",
      linkItems: "About Us",
    },

    {
      link: "/terms-conditions",
      linkItems: "Terms & Conditions",
    },

    {
      link: "/refund-policy",
      linkItems: "Refund-Policy",
    },

    {
      link: "/contact",
      linkItems: "Contact Us",
    },
    {
      link: "/portfolio",
      linkItems: "Portfolio",
    },
  ];

  const apps = [
    {
      link: "https://www.reproneetlearning.com/",
      linkItems: "Reproneet",
    },
    {
      link: "https://www.easydev.in/",
      linkItems: "Easydev",
    },
    {
      link: "https://mvp-dev.dexlabz.com/",
      linkItems: "Dexlabz",
    },
  ];

  return (
    <div>
      <div className=" " id="footer-bg-img">
        <div className=" flex justify-center bg-[rgba(15,23,42,.7)] border-t">
          <div className=" text-white flex flex-col xl:flex-row  xl:w-[75%] mx-5  justify-center tablet:w-[95%] tablet:flex-col tablet:px-5 py-5">
            <div className="flex flex-col md:flex-row w-full 2xl:w-[50%]">
              <div className="my-5 2xl:w-[50%]    tablet:w-[30%] mx-5">
                <div className="w-[25%] tablet:w-[40%] 2xl:w-[15%] -translate-y-8">
                  <img
                    src={require("../../assets/HomeImg/logo-phynlabz.png")}
                    alt=""
                  />
                </div>

                <p className="text-sm text-gray-400 mb-5">
                  Phynlabz excels as an IT consultancy and product company,
                  merging innovation and expertise to provide bespoke solutions.
                  With a skilled team, they deliver cutting-edge services,
                  tailored strategies, and top-tier products, elevating
                  businesses by leveraging the latest in technology for optimal
                  growth and enhanced user experiences.
                </p>

                <div className=" flex items-center text-xl md:text-3xl mt-10">
                  <Link to="" className="me-6 hover:text-[#E8675B]">
                    <FaInstagram />
                  </Link>
                  <Link to="" className="me-6 hover:text-blue-800 ">
                    <FaFacebookF />
                  </Link>
                  <Link to="" className="me-6 hover:text-sky-600">
                    <FaLinkedinIn />
                  </Link>
                  <Link to="" className="me-6 hover:text-red-500">
                    <FaYoutube />
                  </Link>
                </div>
              </div>

              <div className="my-5 md:w-[50%] ms-10">
                <p className="text-xl font-bold mb-5">Useful Links </p>
                <ul>
                  {useLink.map((data) => (
                    <li className="my-3 text-gray-400 hover:text-white">
                      <Link to={data.link} className="">
                        {data.linkItems}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div className="my-5 2xl:w-[50%] mx-5">
                 <p className="text-xl font-bold mb-5">About Us </p>
                 <p className="text-gray-400">
                   Lorem ipsum dolor sit amet consectetur adipisicing elit.
                   Sequi commodi voluptatibus veniam, iste quaerat eveniet,
                   blanditiis nisi, consectetur excepturi necessitatibus
                   doloribus! Doloremque quam repudiandae porro distinctio saepe
                   amet, est vitae!
                 </p>
               </div> */}
            </div>

            <div className="flex flex-col md:flex-row 2xl:w-[50%] ">
              <div className="my-5 md:w-[50%] mx-5">
                <p className="text-xl font-bold mb-5">Apps </p>
                <ul>
                  {apps.map((data) => (
                    <li className="my-3 text-gray-400 hover:text-white">
                      <Link to={data.link} className="">
                        {data.linkItems}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="my-5 md:w-[50%] w-full mx-5">
                <p className="text-xl font-bold mb-5">Contact Us </p>
                <p className="text-gray-400 my-5 font-serif">
                  A-806, Satyam, <br /> Shrey Bavdhan, Pune City,{" "}
                  <br />
                  Pune - 411021, Maharashtra
                </p>

                <p className="my-1">
                  <b>Phone </b>:{" "}
                  <Link to="" className="font-serif">
                    +91 8604681087
                  </Link>
                </p>
                <p className="my-1">
                  <b>Email </b>:{" "}
                  <Link to="" className="font-serif">
                    business@phynlabz.com
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
