import React from 'react'


import Header from '../Header/Header'
import HeroContainer from './HeroContainer/HeroContainer'
import EngineeringContainer from './EngineeringContainer/EngineeringContainer'
import DevelopmentContainer from './DevelopmentContainer/DevelopmentContainer'
import ProfileCard from './ProfileCard/ProfileCard';
import Footer from '../Footer/Footer'
import OurServices from './OurServices/OurServices'
import BoxesContainer from './BoxesContainer/BoxesContainer'



function Home() {
   return (
      <div className='box-border relative'>

         {/* <img src={require('../../assets/HomeImg/hero-bg.png')} className=' opacity-10 right-0 w-[90%] z-0' alt="" /> */}

         <div className=' z-10'>
            <div>
               <Header />
            </div>

            <div>
               <HeroContainer />
            </div>

            <div>
               <EngineeringContainer />
            </div>

            <div>
               <DevelopmentContainer />
            </div>

            <div>
               <ProfileCard />
            </div>
            
            <div>
               <OurServices />
            </div>
            
            <div>
               <BoxesContainer />
            </div>

            <div>
               <Footer />
            </div>
         </div>

      </div>
   )
}

export default Home
