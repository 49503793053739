import React from 'react'

import './App.css'
import { Route, Routes } from 'react-router-dom'


import Exc from './Components/Exc/Exc';


import Home from './Components/Home/Home'
import ContactUs from './Components/ContactUs/ContactUs'
import MultiCarousel from './Components/Home/MultiCarousel/MultiCarousel'
import Career from './Components/Career/Career';
import Clients from './Components/Clients/Clients';
import ServicesDropdown from './Components/Header/ServicesDropdown/ServicesDropdown';


import AboutUs from './Components/AboutUs/AboutUs';
import TermsConditions from './Components/TermsConditions/TermsConditions';
import RefundPolicy from './Components/RefundPolicy/RefundPolicy';
import ProfileCardCarousel from './Components/Home/ProfileCardCarousel/ProfileCardCarousel';
import Protfolio from './Components/Protfolio/Protfolio';




function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/multi" element={<MultiCarousel />} />
        <Route path="/career" element={<Career />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/ser" element={<ServicesDropdown />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/pro" element={<ProfileCardCarousel />} />
        <Route path="/portfolio" element={<Protfolio />} />

        <Route path="/exc" element={<Exc />} />
      </Routes>
    </div>
  );
}

export default App
