import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import MultiCarousel from '../MultiCarousel/MultiCarousel'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'


import "react-multi-carousel/lib/styles.css";
import "./ProfileCard.css";
import ProfileCardCarousel from '../ProfileCardCarousel/ProfileCardCarousel';


const profileData = [
   {
      "name": "Ankit Soni",
      "branch": "Mobile Developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Shashank Shekhar",
      "branch": "Senior Java Developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Akas Rajpoot",
      "branch": "software developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Shiva Kushwaha ",
      "branch": "software developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Vivek PrajaPati",
      "branch": "software developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Rahul Gautam",
      "branch": "software developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
   {
      "name": "Rahul Gautam",
      "branch": "software developer",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
   },
]



function ProfileCard() {


   const scrollContainerRef = useRef(null);
   const [scrollLeft, setScrollLeft] = useState(0);
   const [maxScrollWidth, setMaxScrollWidth] = useState(0);



   const handleLeftScroll = () => {
      console.log('Left wale btn pr click kiya gya hai ')

      const newScrollLeft = scrollLeft - 500; // Adjust the scroll amount as needed
      setScrollLeft(newScrollLeft);
      scrollContainerRef.current?.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

   }

   const handleRightScroll = () => {
      console.log('Right wale btn pr click kiya gya hai ')

      const newScrollLeft = scrollLeft + 500; // Adjust the scroll amount as needed
      setScrollLeft(newScrollLeft);
      scrollContainerRef.current?.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

   }





   


   return (
      <div>

         <div className="bg-gray-900 flex justify-center ">

            <div className=" text-white flex flex-col  w-full xl:w-[80%] mx-5 items-center justify-center tablet:w-[95%]  my-10  ">

               <div className="text-center text-2xl font-bold my-10 xl:text-5xl xl:mb-20 pb-5 border-b tablet:text-4xl">

                  <p className="">Profiles</p>

               </div>

               <ProfileCardCarousel />

            </div>

         </div>



      </div>


   )
}



export default ProfileCard
