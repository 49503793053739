import React from 'react'


import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';

function ProfileCardCarousel() {

    const profileData = [
        {
            "name": "Ankit Soni",
            "branch": "Mobile Developer",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Shashank Shekhar",
            "branch": "Senior Java Developer",
            "description": "12+ Year of Experience"
        },
        {
            "name": "Vivek Kumar",
            "branch": "Marketting Lead",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Parvez Alam",
            "branch": "Operations Head",
            "description": "20+ Year Experience Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Kushal Kundu",
            "branch": "Android Developer",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Manoj",
            "branch": "QA Lead ",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Vasudev Raghav ",
            "branch": "Backend Developer",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Krishna Kumar Gautam",
            "branch": "Frontend Engineer",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Manvendra Singh",
            "branch": " Full Stack Developer ",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
        {
            "name": "Ashutosh ",
            "branch": " Front end developer ",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
        },
    ]


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3.1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 1.5,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 1.5,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <div className="parent w-full">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
                arrows={false}
            >
                {profileData.map((data, index) => {
                    return (
                        <div className='flex min-w-[25rem]  mx-10  ' >


                            <div className={` flex items-center justify-center flex-col mx-2 `}>

                                <div className=" w-[30%] relative top-16 tablet:top-20 ">
                                    <img src={require('../../../assets/HomeImg/men-avtar.png')} alt="" className='rounded-[50%] bg-white p-2 ' />
                                </div>

                                <div className="text-center text-gray-400 pt-20  w-full border-2 rounded-lg px-5 flex justify-center flex-col items-center ">
                                    <p className='font-bold text-2xl my-5 text-white'>{data.name}</p>
                                    <p className='pb-5 text-sm text-gray-500'>{data.branch}</p>
                                    <div className='h-[2px] bg-orange-500 w-[20%]'></div>
                                    <p className='text-xs mt-5 mb-10 px-5'>{data.description} </p>
                                    <div className="flex justify-end  w-full">
                                        <p className=" pe-1 text-orange-500 "><Link to="" >Read More</Link></p>
                                    </div>
                                </div>

                            </div>


                        </div>
                    );
                }
                )
                }
            </Carousel>
        </div>
    )
}

export default ProfileCardCarousel