import React from "react";

import "./EngineeringContainer.css";

import { BsFillPatchPlusFill } from "react-icons/bs";
import { TbBulbFilled } from "react-icons/tb";
import {
  FaHeadSideVirus,
  FaMobileRetro,
  FaRobot,
  FaTowerObservation,
} from "react-icons/fa6";

function EngineeringContainer() {
  const cardData = [
    {
      divStyle: "xl:rounded-tl-xl",
      icon: (
        <BsFillPatchPlusFill className="text-3xl xl:text-5xl my-5 text-orange-400" />
      ),
      heading: "Scalable software solutions",
      discretion:
        "Our focus on scalable software solutions ensures that the systems we develop can seamlessly accommodate growing user bases and increased data volumes without compromising performance or user experience.",
    },
    {
      icon: (
        <FaTowerObservation className="text-3xl xl:text-5xl my-5 text-orange-400" />
      ),
      heading: "Comprehensive tech expertise",
      discretion:
        "From web and mobile development to AI, machine learning, and beyond, we leverage our broad tech expertise to deliver innovative solutions that drive business growth and digital transformation.",
    },
    {
      divStyle: "xl:rounded-tr-xl",
      icon: <FaRobot className="text-3xl xl:text-5xl my-5 text-orange-400" />,
      heading: "Expert problem solvers",
      discretion:
        "With a systematic and analytical approach, we identify and resolve issues efficiently, ensuring smooth project execution and successful outcomes for our clients.",
    },
    {
      divStyle: "xl:rounded-bl-xl",
      icon: (
        <FaMobileRetro className="text-3xl xl:text-5xl my-5 text-orange-400" />
      ),
      heading: "Efficiency through Technology",
      discretion:
        " We optimize operations, save time, and boost productivity with cutting-edge solutions.",
    },
    {
      icon: (
        <TbBulbFilled className="text-3xl xl:text-5xl my-5 text-orange-400" />
      ),
      heading: "Crafting Digital Transformations",
      discretion:
        ": We design tailored strategies and implement innovative technologies to reshape your business for success in the digital age",
    },
    {
      divStyle: "xl:rounded-br-xl",
      icon: (
        <FaHeadSideVirus className="text-3xl xl:text-5xl my-5 text-orange-400" />
      ),
      heading: "Cutting-Edge Tech Mastery",
      discretion:
        " We lead the way in harnessing the latest technologies to create innovative solutions that redefine industry standards.",
    },
  ];

  // console.log(cardData[0].icon)

  return (
    <div>
      <div className=" flex justify-center engineering-bg-image ">
        <div className="flex justify-center  bg-[rgba(15,23,42,.7)]  w-full">
          <div className=" text-white flex flex-col  xl:w-[80%] mx-5 items-center justify-center my-10 mb-20 tablet:w-[95%] tablet:flex-col">
            <div className="text-center text-xl font-bold my-10 xl:text-3xl xl:mb-20 pb-5 border-b">
              <p className="">
                Creating, Building, and Modernizing with Technology.
              </p>
            </div>

            <div className="w-full flex flex-col xl:flex-row flex-wrap tablet:flex-row">
              {cardData.map((item, index) => {
                const Icon = item.icon;

                console.log(Icon);

                return (
                  <div
                    key={index}
                    className={`xl:w-[33.33%] tablet:w-[50%] px-5  py-3 pb-5 flex items-center justify-center flex-col text-center border cursor-pointer  border-gray-400 hover:bg-gray-600 hover:border-slate-600 ${
                      index % 2 === 0 ? "bg-[#2a303e] " : "bg-gray-800"
                    } ${item.divStyle}`}
                  >
                    {item.icon}

                    <p className="font-bold text-lg xl:text-2xl tablet:text-3xl text-white mb-2">
                      {item.heading}
                    </p>
                    <p className="text-xs xl:text-sm xl:my-2 text-gray-400 tablet:text-base tablet:mt-2">
                      {item.discretion}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngineeringContainer;
