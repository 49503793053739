import React from 'react'


import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './RefundPolicy.css'

function RefundPolicy() {


    const Access = "Deliver a high quality web design. *Support our customers once signing a contract. "


    const AccessArr = Access.split('*')

    const Shipping = "Streamline an expanded array of web. *Pursue web-enabled niche markets with professionalsDeliver a high quality web design. "


    const ShippingArr = Shipping.split('*')





    return (
        <div>

            <Header />

            <div className="rp-bg-img">
                <div className=" bg-[rgba(15,23,42,.7)] text-white flex justify-center flex-col items-center" >
                    <div className="p-10 bg-gradient-to-r text-xl md:text-3xl font-bold 2xl:mb-10 bg-[rgba(15,23,42,.9)] w-[90%] 2xl:w-[75%] rounded-2xl text-center my-10 ">
                        
                        <p className="">REFUND POLICY</p>

                    </div>

                    <div className="w-[90%] 2xl:w-[60%] tablet:w-[70%] bg-[rgba(15,23,42,.9)] py-5  my-5 mb-10  px-5  md:px-10 rounded-3xl">



                        <ul className="list-decimal font-mono mx-5 w-full ">
                            <li className='font-bold text-base md:text-xl my-8'>Access to Our Sites
                                {
                                    AccessArr.map((data) =>


                                        <ul className="font-sans list-disc m-5">
                                            <li className='font-normal text-sm md:text-lg my-2 '>{data}</li>
                                        </ul>


                                    )
                                }
                            </li>
                            <li className='font-bold text-base md:text-xl my-4'>Shipping and Delivery (Not Applicable)
                                {
                                    ShippingArr.map((data) =>


                                        <ul className="font-sans list-disc m-5">
                                            <li className='font-normal text-sm md:text-lg my-4 '>{data}</li>
                                        </ul>


                                    )
                                }
                            </li>
                        </ul>

                    </div>

                </div>
            </div>

            <Footer />

        </div>
    )
}

export default RefundPolicy