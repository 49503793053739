import React from 'react'


import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './TermsConditions.css'

function TermsConditions() {

    const tncHeading = "Deliver a high quality web design *Support our customers once signing a contract *Streamline an expanded array of web *Pursue web-enabled niche markets with professionalsDeliver a high quality web design *Support our customers once signing a contract *Streamline an expanded array of web *Pursue web-enabled niche markets with professionalsDeliver a high quality web design *Support our customers once signing a contract *Streamline an expanded array of web *Pursue web-enabled niche markets with professionalsDeliver a high quality web design *Support our customers once signing a contract *Streamline an expanded array of web *Pursue web-enabled niche markets with professionals"



    const tncAar = tncHeading.split('*')
    console.log(tncAar)

    return (
        <div>

            <Header />

            <div className="tnc-bg-img">
                <div className=" bg-[rgba(15,23,42,.7)] text-white flex justify-center flex-col items-center" >
                    <div className="p-10 bg-gradient-to-r text-xl md:text-3xl font-bold 2xl:mb-10 bg-[rgba(15,23,42,.9)] w-[90%] 2xl:w-[75%] rounded-2xl text-center my-10 ">
                        <p className="">TERMS & CONDITIONS</p>

                    </div>

                    <div className="w-[90%] 2xl:w-[60%] tablet:w-[70%] bg-[rgba(15,23,42,.9)] py-5  my-5 mb-10  px-5  md:px-10 rounded-3xl">

                        <div className=" my-10 text-lg md:text-xl ">
                            <p className="">Please read these terms and conditions ("Terms") carefully before using the website.</p>
                            <p className=" my-5"><a href="https://www.phynlabz.com" class="text-red-400 mx-0 font-bold text-lg md:text-xl">www.phynlabz.com</a> (the "Website") operated by Reproneet Learning "us," "we," or "our".</p>
                        </div>

                        <ul className="list-disc px-5">
                            {
                                tncAar.map((data) =>
                                    <li className='text-sm md:text-lg my-4'>{data}</li>
                                )
                            }
                        </ul>

                    </div>

                </div>
            </div>

            <Footer />

        </div>
    )
}

export default TermsConditions