import React, { useDebugValue, useEffect, useState } from "react";

import "./ContactHeroContainer.css";

import axios, { HttpStatusCode } from "axios";

import { ApiUrl } from "../../../Config/ApiUrl";

function ContactHeroContainer() {
  const [showPopup, setShowPopup] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async () => {
    if (
      name.length === 0 &&
      email.length === 0 &&
      message.length === 0 &&
      contact.length === 0
    ) {
      setError(true);
      setErrorMessage("All fields are required...");
      return;
    }

    if (name === undefined || name === null || name.length === 0) {
      setError(true);
      setErrorMessage("Name is required...");
      return;
    }
    if (email === undefined || email === null || email.length === 0) {
      setError(true);
      setErrorMessage("Email is required...");
      return;
    }
    if (contact === undefined || contact === null || contact.length === 0) {
      setError(true);
      setErrorMessage("Mobile No is required...");

      return;
    }
    if (message === undefined || message === null || message.length === 0) {
      setError(true);
      setErrorMessage("Message is required...");
      return;
    }

    var bodyData = {
      name: name,
      email: email,
      contact: contact,
      message: message,
    };

    try {
      const { data, status } = await axios.post(ApiUrl, bodyData);

      // console.log(data)

      if (status === HttpStatusCode.Ok) {
        setName("");
        setEmail("");
        setContact("");
        setMessage("");
        setError(false);
        setShowPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="bg-gray-900 flex justify-center flex-col items-center contact-bg-img">
        <div className="bg-[rgba(15,23,42,.7)] flex justify-center flex-col items-center w-full">
          <div className=" text-white flex flex-col xl:flex-row  xl:w-[70%] 6xl:w-[60%] mx-5 items-start  justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 mt-10 font-bold text-5xl py-10">
            <p className="">Contact Us</p>
          </div>

          <div className=" text-white flex flex-col xl:flex-row  xl:w-[70%] 6xl:w-[60%] mx-5 items-start  justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 my-20">
            <div className="xl:w-[50%] tablet:w-[50%] my-5 me-10">
              <p className="font-bold text-2xl xl:text-4xl mb-5">
                Do you have any questions?
              </p>
              <p className="text-xs xl:text-base">
                We appreciate your interest in our products and services. If you
                have any questions, feedback, or inquiries, feel free to get in
                touch with us.
              </p>
            </div>

            <div className="w-full xl:w-[50%] tablet:w-[50%] ">
              {error && (
                <p className="bg-red-200 text-red-800 p-5 my-3 rounded">
                  {errorMessage}
                </p>
              )}

              <p className="">
                <input
                  type="text"
                  className="w-full  text-gray-300 p-2 my-2 rounded border"
                  style={{ background: "rgba(15,23,42,.9)" }}
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </p>
              <p className="">
                <input
                  type="text"
                  className="w-full  text-gray-300 p-2 my-2 rounded border"
                  style={{ background: "rgba(15,23,42,.9)" }}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </p>
              <p className="">
                <input
                  type="text"
                  className="w-full  text-gray-300 p-2 my-2 rounded border"
                  style={{ background: "rgba(15,23,42,.9)" }}
                  placeholder="Mobile No."
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </p>
              <p className="">
                <textarea
                  className="w-full  text-gray-300 p-2 my-2 rounded border"
                  rows={4}
                  style={{ background: "rgba(15,23,42,.9)" }}
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </p>
              <p className="">
                <button
                  className="w-full  text-black p-2 my-2 rounded bg-white border-2 border-transparent hover:border-gray-500 text-left"
                  onClick={() => handleSubmit()}
                >
                  Send Message
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="bg-[rgba(128,128,128,.5)] w-full h-screen fixed top-0 left-0 ">
          <div className="popup-bg h-[18rem] md:h-[25rem] w-[80%]  2xl:w-[30%] tablet:w-[70%] absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto px-5 md:p-10 rounded-lg flex items-center justify-center flex-col">
            <div className=" mb-5 flex items-center justify-center">
              <img
                src={require("../../../assets/HomeImg/succesTik.png")}
                className="w-[30%] md:w-[50%]"
                alt=""
              />
            </div>
            <p className=" text-sm tablet:text-3xl  text-center 2xl:text-xl">
              We have received your request. Our Team will connect with you
              shortly.
            </p>

            <button
              onClick={() => setShowPopup(false)}
              className="bg-orange-400 hover:bg-transparent border-2 border-orange-400 px-10 py-2 rounded-full mt-10 text-xs 2xl:text-lg tablet:text-2xl text-white hover:text-orange-400 font-bold"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactHeroContainer;
