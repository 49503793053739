import React from 'react'


import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

function MultiCarousel() {



   const profileData = [
      {
         "name": "Krishna Kumar",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Vipul Savita",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Akas Rajpoot",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Shiva Kushwaha ",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Vivek PrajaPati",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Rahul Gautam",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
      {
         "name": "Rahul Gautam",
         "branch": "software developer",
         "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam mollitia itaque velit vel molestias enim exercitationem consequuntur incidunt suscipit"
      },
   ]





   return (
      <div className="flex w-full overflow-x-scroll scrollbar &::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">

         <button>
            <BsFillArrowLeftCircleFill />
         </button>


         {
            profileData.map((data) =>
               <div className='flex min-w-[20rem]  mx-10'>
                  <div className=" flex items-center justify-center flex-col mx-2 ">

                     <div className=" w-[50%] relative top-16 tablet:top-20">
                        <img src={require('../../../assets/HomeImg/kk.jpeg')} alt="" className='rounded-[50%]  ' />
                     </div>

                     <div className="text-center bg-red-300 text-gray-400 pt-20  w-full border-2 rounded-lg px-5 flex justify-center flex-col items-center ">
                        <p className='font-bold text-2xl my-5 text-black'>{data.name}</p>
                        <p className='pb-5 text-sm text-gray-500'>{data.branch}</p>
                        <div className='h-[2px] bg-orange-500 w-[20%]'></div>
                        <p className='text-xs mt-5 mb-10 px-5'>{data.description} </p>
                        <div className="flex justify-end  w-full">
                           <p className=" pe-1 text-orange-500 "><Link to="" >Read More</Link></p>
                        </div>
                     </div>




                  </div>
               </div>
            )
         }


         <button>
            <BsFillArrowRightCircleFill />
         </button>


      </div>
   )
}

export default MultiCarousel
